import styled, { css } from "styled-components";
import * as React from "react";
import { theme, centerFlex } from "../../utils/style";
import { darken } from "polished";

export interface ButtonProps {
  outline?: boolean;
  fullwidth?: boolean;
}

function buttonStyle(p: ButtonProps) {
  return p => css`
    text-decoration: none;
    color: ${theme.primaryContrastColor};
    background-color: ${theme.primaryColor};
    border: 1px solid ${theme.primaryColor};
    cursor: pointer;
    &:hover {
      background-color: ${darken(0.1, theme.primaryColor)};
      border: 1px solid ${darken(0.1, theme.primaryColor)};
    }

    padding: 0 2rem;
    white-space: nowrap;
    min-width: 150px;
    height: 36px;
    display: ${!p.fullwidth ? "inline-flex" : "flex"};
    justify-content: center;
    align-items: center;
    /* border-radius: 36px; */

    ${p.outline &&
      css`
        background-color: transparent;
        color: ${theme.primaryColor};

        &:hover {
          background-color: transparent;
          color: ${darken(0.1, theme.primaryColor)};
        }
      `}
  `;
}

export const LinkButton = styled.a<ButtonProps>`
  ${p => buttonStyle(p)}
`;

export const Button = styled.button<ButtonProps>`
  ${p => buttonStyle(p)}
`;

export const IconButton = styled.a<{ color: string; outline?: boolean }>`
  color: ${p => (p.outline ? theme.primaryColor : p.color)};
  ${centerFlex};
  width: 3rem;
  height: 3rem;
  border-radius: 2rem;
  font-size: ${p => (p.outline ? "1.75rem" : "2.5rem")};
  /* background: red; */
`;
