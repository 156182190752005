import * as React from "react";
import styled, { css } from "styled-components";

export const PAGE_WIDTH = 1200;
export const READ_WIDTH = 800;

export const TABLET_BR = 800;

const sizes = {
  tablet: TABLET_BR,
  phone: 576
};

// Iterate through the sizes and create a media template
export const maxMedia = Object.keys(sizes).reduce((acc: any, label: any) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;

  return acc;
}, {}) as Record<keyof typeof sizes, typeof css>;

export const minMedia = Object.keys(sizes).reduce((acc: any, label: any) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;

  return acc;
}, {}) as Record<keyof typeof sizes, typeof css>;

export const theme = {
  primaryColor: "#0d6797",
  primaryColorLight: "#238ab3",
  primaryContrastColor: "#fff"
};

export const MaxWidth = styled(
  ({
    greyGradient,
    maxWidth,
    ...props
  }: {
    greyGradient?: boolean;
    maxWidth?: number;
    children?: any;
    id?: any;
    style?: React.CSSProperties;
  }) => (
    <div {...props}>
      <div className="inner">{props.children}</div>
    </div>
  )
)`
  display: flex;
  width: 100%;
  justify-content: center;

  ${p =>
    p.greyGradient &&
    css`
      background: #f5f5f5;
    `}

  .inner {
    position: relative;
    flex-grow: 1;
    padding: 1rem;
    width: 100%;
    max-width: ${p => p.maxWidth || PAGE_WIDTH}px;
  }
`;

export const fillAbs = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const centerFlex = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;
